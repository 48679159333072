import get from 'get-value';
import { arrayOf, func, number, object, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { FormHelperText, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { getTotalManualPages } from '../../../selectors/codex-editor';
import { getTocNodes } from '../../../selectors/table-of-content';
import DigibookPropTypes from '../digibook-proptypes';

const styles = {
  bold: {
    fontWeight: 'bold',
  },
  numericColumn: {
    minWidth: '45px',
  },
};

function findPreviousIndexOnSameLevel(array, current) {
  const currentNode = array.find(item => item.id === current.nodeId);
  const itemsOnSameLevel = array.filter(item => item.level === currentNode.level);
  const currentIndex = itemsOnSameLevel.findIndex(item => item.id === current.nodeId);
  const prev = itemsOnSameLevel[currentIndex - 1];

  return prev && array.findIndex(item => item.id === prev.id);
}

export class DigibookToc extends React.PureComponent {
  handleBlur(ev, current) {
    const {
      handleBlur,
      setFieldValue,
      tocNodes,
      values: { pageRanges },
    } = this.props;

    handleBlur(ev);

    const prev = findPreviousIndexOnSameLevel(tocNodes, current);
    if (prev && ev.target.value > 0) {
      if (!pageRanges[prev].to) setFieldValue(`pageRanges[${prev}].to`, ev.target.value - 1);
      if (!pageRanges[prev].keepMediaVisibleTo)
        setFieldValue(`pageRanges[${prev}].keepMediaVisibleTo`, ev.target.value - 1);
    }
  }

  parsePageNumber = page => {
    const pageNumber = parseInt(page, 10);
    return Number.isNaN(pageNumber) ? undefined : pageNumber;
  };

  render() {
    const {
      values: { pageRanges },
      errors,
      setFieldValue,
      tocNodes,
      classes,
      totalManualPages,
    } = this.props;

    function getErrorMessages() {
      if (!errors.pageRanges) return [];

      const errorMessages = [];

      errors.pageRanges.forEach(pageRange => {
        if (pageRange) {
          const messages = Object.values(pageRange).filter(value => typeof value === 'string');
          errorMessages.push(...messages);
        }
      });

      const uniqueErrorMessages = [...new Set(errorMessages)];

      return uniqueErrorMessages;
    }

    return (
      <>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Level</TableCell>
              <TableCell className={classes.numericColumn}>From</TableCell>
              <TableCell className={classes.numericColumn}>Media to</TableCell>
              <TableCell className={classes.numericColumn}>Pages to</TableCell>
              <TableCell className={classes.numericColumn}>Manual from</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageRanges.map((pageRange, index) => (
              <TableRow key={pageRange.nodeId}>
                <TableCell
                  className={tocNodes[index].level === 0 ? classes.bold : ''}
                  style={{ padding: 5 * tocNodes[index].level }}
                >
                  {tocNodes[index].name}
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    type="number"
                    name={`pageRanges[${index}].from`}
                    value={pageRange.from !== undefined ? pageRange.from : ''}
                    onChange={ev => setFieldValue(ev.target.name, this.parsePageNumber(ev.target.value))}
                    onBlur={ev => this.handleBlur(ev, pageRange)}
                    error={!!get(errors, ['pageRanges', index, 'from'])}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    type="number"
                    name={`pageRanges[${index}].keepMediaVisibleTo`}
                    value={pageRange.keepMediaVisibleTo !== undefined ? pageRange.keepMediaVisibleTo : ''}
                    onChange={ev => setFieldValue(ev.target.name, this.parsePageNumber(ev.target.value))}
                    error={!!get(errors, ['pageRanges', index, 'keepMediaVisibleTo'])}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    type="number"
                    name={`pageRanges[${index}].to`}
                    value={pageRange.to !== undefined ? pageRange.to : ''}
                    onChange={ev => setFieldValue(ev.target.name, this.parsePageNumber(ev.target.value))}
                    error={!!get(errors, ['pageRanges', index, 'to'])}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    type="number"
                    disabled={totalManualPages === 0}
                    name={`pageRanges[${index}].manualFrom`}
                    value={pageRange.manualFrom !== undefined ? pageRange.manualFrom : ''}
                    onChange={ev => setFieldValue(ev.target.name, this.parsePageNumber(ev.target.value))}
                    error={!!get(errors, ['pageRanges', index, 'manualFrom'])}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {getErrorMessages().map(errorMessage => (
          <FormHelperText key={errorMessage} error>
            {errorMessage}
          </FormHelperText>
        ))}
      </>
    );
  }
}

DigibookToc.propTypes = {
  values: DigibookPropTypes.isRequired,
  errors: object.isRequired,
  handleBlur: func.isRequired,
  setFieldValue: func.isRequired,
  tocNodes: arrayOf(object.isRequired).isRequired,
  totalManualPages: number,
  classes: shape({
    bold: string.isRequired,
    numericColumn: string.isRequired,
  }).isRequired,
};

DigibookToc.defaultProps = {
  totalManualPages: 0,
};

const mapStateToProps = (state, ownProps) => ({
  tocNodes: getTocNodes(state, ownProps.values.module),
  totalManualPages: getTotalManualPages(state),
});

export const ConnectedDigibookToc = connect(mapStateToProps)(DigibookToc);

export default withStyles(styles)(ConnectedDigibookToc);
