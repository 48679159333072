import { bool, func } from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { error } from '../../../../actions/notification';
import pdfService from '../../../../services/pdf';

import { setCurrentPage, setTotalManualPages, setTotalPages } from '../../../../actions/codex-editor';
import getSecuredFileById from '../../../../selectors/secured-file';

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
  },
}));

const normalise = (value, max) => (value * 100) / max;

export default function Loader({ onLayerFetched, setLoading, loading }) {
  const classes = useStyles();

  const [percentageLoaded, setPercentageLoaded] = useState(0);

  const dispatch = useDispatch();

  const fileIds = useSelector(s => s.codexEditor.files);
  const bookLayer = useSelector(s => getSecuredFileById(s, fileIds.bookLayer));
  const answerLayer = useSelector(s => getSecuredFileById(s, fileIds.answerLayer));
  const cover = useSelector(s => getSecuredFileById(s, fileIds.cover));
  const backCover = useSelector(s => getSecuredFileById(s, fileIds.backCover));
  const manual = useSelector(s => getSecuredFileById(s, fileIds.manual));

  const fetchPdfFor = useCallback(
    async (layer, onDownloadProgress) => {
      try {
        const pdf = await pdfService.fetch(layer.id, layer.downloadUrl, onDownloadProgress);

        onLayerFetched(layer, pdf);
      } catch (e) {
        error(e.message, 'Oops :(');
      }
    },
    [onLayerFetched],
  );

  useEffect(() => {
    async function fetchBook() {
      const onDownloadProgress = progressEvent => {
        const { loaded, total } = progressEvent;

        setPercentageLoaded(normalise(loaded, total));
      };

      setLoading(true);

      try {
        const pdf = await pdfService.fetch(bookLayer.id, bookLayer.downloadUrl, onDownloadProgress);
        onLayerFetched('book', pdf);
        dispatch(setCurrentPage(0));
        dispatch(setTotalPages(pdf.numPages));

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }

    if (bookLayer) {
      fetchBook();
    } else {
      onLayerFetched('book', undefined);
    }
  }, [bookLayer, fetchPdfFor, setLoading, dispatch, onLayerFetched]);

  useEffect(() => {
    async function fetchAnswers() {
      const pdf = await pdfService.fetch(answerLayer.id, answerLayer.downloadUrl, () => {});
      onLayerFetched('answer', pdf);
    }

    if (answerLayer) {
      fetchAnswers();
    } else {
      onLayerFetched('answer', undefined);
    }
  }, [answerLayer, fetchPdfFor, onLayerFetched]);

  useEffect(() => {
    async function fetchCover() {
      const pdf = await pdfService.fetch(cover.id, cover.downloadUrl, () => {});
      onLayerFetched('cover', pdf);
    }

    if (cover) {
      fetchCover();
    } else {
      onLayerFetched('cover', undefined);
    }
  }, [cover, fetchPdfFor, onLayerFetched]);

  useEffect(() => {
    async function fetchBackCover() {
      const pdf = await pdfService.fetch(backCover.id, backCover.downloadUrl, () => {});
      onLayerFetched('backCover', pdf);
    }

    if (backCover) {
      fetchBackCover();
    } else {
      onLayerFetched('backCover', undefined);
    }
  }, [backCover, fetchPdfFor, onLayerFetched]);

  useEffect(() => {
    async function fetchManual() {
      const pdf = await pdfService.fetch(manual.id, manual.downloadUrl, () => {});
      dispatch(setTotalManualPages(pdf.numPages));
      onLayerFetched('manual', pdf);
    }

    if (manual) {
      fetchManual();
    } else {
      onLayerFetched('manual', undefined);
    }
  }, [manual, fetchPdfFor, onLayerFetched, dispatch]);

  return (
    <>
      {loading && (
        <LinearProgress
          role="progressbar"
          className={classes.progress}
          variant="determinate"
          value={percentageLoaded}
        />
      )}
    </>
  );
}

Loader.propTypes = {
  onLayerFetched: func.isRequired,
  setLoading: func.isRequired,
  loading: bool.isRequired,
};
