import get from 'get-value';
import shortid from 'shortid';
import { CodexEditorActionType } from '../action-types';
import api from '../services/api';

import {
  getCurrentHierarchyMethodId,
  getCurrentHierarchyModuleId,
  getCurrentHierarchySubjectId,
  getCurrentPublishingHouseId,
} from '../selectors/context';
import { getDigibookById } from '../selectors/digibook';
import { getTocByModuleId, getTocNodes, getTocNodesWithPageMapping } from '../selectors/table-of-content';

import tableOfContentConstants from '../constants/table-of-content';
import { getAnswerSetsForCurrentPages, getInitialState, getSelectedAnswerSetId } from '../selectors/codex-editor';
import { forEachNodeRecursive } from '../utils/table-of-content-utils';
import { updateWeights } from '../utils/update-dnd-weights';
import { fetchDigibookById, fetchDigibooksByModule, forceFetchDigibookById } from './digibook';
import { fetchMethodsBySubject } from './method';
import { fetchModulesByMethod } from './module';
import { fetchSubjectsByPublishingHouse } from './subject';
import { fetchTableOfContentFor } from './table-of-content';

export const resetEditorState = () => ({
  type: CodexEditorActionType.RESET_INITIAL_STATE,
});

const setInitialEditorState = initialState => ({
  type: CodexEditorActionType.SET_INITIAL_STATE,
  payload: {
    initialState,
  },
});

export const codexEditorSaved = initialState => ({
  type: CodexEditorActionType.EDITOR_SAVE_SUCCESS,
  payload: {
    initialState,
  },
});

export const setFileForLayer = (layer, file) => ({
  type: CodexEditorActionType.SET_FILE_FOR_LAYER,
  payload: {
    layer,
    file,
  },
});

export const initLinkAreas = linkAreas => ({
  type: CodexEditorActionType.INIT_LINK_AREAS,
  payload: {
    linkAreas,
  },
});

export const editLinkArea = (digibookId, linkArea, index) => async dispatch => {
  const { data: savedLinkArea } = await api.put(`/shell/digibooks/${digibookId}/link-areas/${linkArea.id}`, linkArea);

  return dispatch({
    type: CodexEditorActionType.EDIT_LINK_AREA,
    payload: {
      linkArea: savedLinkArea,
      index,
    },
  });
};

export const editLinkAreaShape = (digibookId, linkArea, pages, index) => async dispatch => {
  const { data: savedLinkArea } = await api.put(`/shell/digibooks/${digibookId}/link-areas/${linkArea.id}`, linkArea);

  return dispatch({
    type: CodexEditorActionType.EDIT_LINK_AREA_SHAPE,
    payload: {
      shape: savedLinkArea.shape,
      pages,
      index,
    },
  });
};

export const setActiveTab = activeTab => ({
  type: CodexEditorActionType.SET_ACTIVE_TAB,
  payload: {
    activeTab,
  },
});

export const addLinkAreaForCurrentPages = (digibookId, linkArea) => async dispatch => {
  const { data } = await api.post(`/shell/digibooks/${digibookId}/link-areas`, linkArea);
  return dispatch({
    type: CodexEditorActionType.ADD_LINK_AREA,
    payload: data,
  });
};

export const deleteLinkArea = (digibookId, linkArea) => async dispatch => {
  await api.delete(`/shell/digibooks/${digibookId}/link-areas/${linkArea.id}`);
  return dispatch({
    type: CodexEditorActionType.DELETE_LINK_AREA,
    payload: {
      linkArea,
    },
  });
};

export const toggleIsDrawing = () => ({
  type: CodexEditorActionType.TOGGLE_IS_DRAWING,
});

export const disableIsDrawing = () => ({
  type: CodexEditorActionType.DISABLE_IS_DRAWING,
});

export const setHierarchyForDigibookLink = (publishingHouseId, subjectId, methodId, moduleId) => ({
  type: CodexEditorActionType.SET_HIERARCHY_FOR_DIGIBOOK_LINK,
  payload: {
    publishingHouseId,
    subjectId,
    methodId,
    moduleId,
  },
});

export const setCurrentNodeForMediaContext = nodeId => ({
  type: CodexEditorActionType.SET_CURRENT_NODE_FOR_MEDIA_CONTEXT,
  payload: {
    nodeId,
  },
});

export const clearCurrentNodeForMediaContext = () => ({
  type: CodexEditorActionType.CLEAR_CURRENT_NODE_FOR_MEDIA_CONTEXT,
});

export const setTotalPages = totalPages => ({
  type: CodexEditorActionType.SET_TOTAL_PAGES,
  payload: {
    totalPages,
  },
});

export const setTotalManualPages = totalManualPages => ({
  type: CodexEditorActionType.SET_TOTAL_MANUAL_PAGES,
  payload: {
    totalManualPages,
  },
});

export const setScale = scale => ({
  type: CodexEditorActionType.SET_SCALE,
  payload: {
    scale,
  },
});

export const setViewport = (width, height) => ({
  type: CodexEditorActionType.SET_VIEWPORT,
  payload: {
    viewport: {
      width,
      height,
    },
  },
});

export const setObjectMoving = isObjectMoving => ({
  type: CodexEditorActionType.SET_OBJECT_MOVING,
  payload: {
    isObjectMoving,
  },
});

export const addAnswerSet = (digibookId, currentPages) => async dispatch => {
  const body = {
    pages: currentPages,
    answers: [],
    shape: {
      top: 0.02,
      left: 0.04,
      size: 0.025,
    },
  };

  const { data } = await api.post(`/shell/digibooks/${digibookId}/answer-sets`, body);

  return dispatch({ type: CodexEditorActionType.ADD_ANSWER_SET, payload: data });
};

export const removeAnswerSet = (digibookId, answerSetId) => async dispatch => {
  await api.delete(`/shell/digibooks/${digibookId}/answer-sets/${answerSetId}`);

  dispatch({
    type: CodexEditorActionType.REMOVE_ANSWER_SET,
    payload: {
      id: answerSetId,
    },
  });
};

export const setSelectedAnswerSet = id => ({
  type: CodexEditorActionType.SET_SELECTED_ANSWER_SET,
  payload: {
    id,
  },
});

export const clearSelectedAnswerSet = () => ({
  type: CodexEditorActionType.CLEAR_SELECTED_ANSWER_SET,
});

export const setSelectedAnswer = answerId => ({
  type: CodexEditorActionType.SET_SELECTED_ANSWER,
  payload: {
    id: answerId,
  },
});

export const clearSelectedAnswer = () => ({
  type: CodexEditorActionType.CLEAR_SELECTED_ANSWER,
});

export const addAnswerToSet = (digibookId, answer, setId) => async (dispatch, getState) => {
  const answerSets = getAnswerSetsForCurrentPages(getState());

  const initialAnswerSet = answerSets.find(set => set.id === setId);

  const modifiedAnswerSet = {
    ...initialAnswerSet,
    answers: [...initialAnswerSet.answers, answer],
  };

  const { data: updatedAnswerSet } = await api.put(
    `/shell/digibooks/${digibookId}/answer-sets/${setId}`,
    modifiedAnswerSet,
  );

  dispatch({
    type: CodexEditorActionType.UPDATE_ANSWER_SET_ANSWERS,
    payload: {
      set: updatedAnswerSet,
      selectedAnswer: updatedAnswerSet.answers[updatedAnswerSet.answers.length - 1].id,
    },
  });
};

export const addAnswerGroupToSet = (digibookId, setId) => async (dispatch, getState) => {
  const answerSets = getAnswerSetsForCurrentPages(getState());

  const initialAnswerSet = answerSets.find(set => set.id === setId);

  const modifiedAnswerSet = {
    ...initialAnswerSet,
    answers: [...initialAnswerSet.answers, { members: [] }],
  };

  const { data: updatedAnswerSet } = await api.put(
    `/shell/digibooks/${digibookId}/answer-sets/${setId}`,
    modifiedAnswerSet,
  );

  dispatch({
    type: CodexEditorActionType.UPDATE_ANSWER_SET_ANSWERS,
    payload: {
      set: updatedAnswerSet,
      selectedAnswer: updatedAnswerSet.answers[updatedAnswerSet.answers.length - 1].id,
    },
  });
};

export const removeAnswerFromSet = (digibookId, answerId, setId) => async (dispatch, getState) => {
  const answerSets = getAnswerSetsForCurrentPages(getState());

  const initialAnswerSet = answerSets.find(set => set.id === setId);

  const getNextAnswers = () => {
    if (initialAnswerSet.answers.find(x => x.id === answerId)) {
      return initialAnswerSet.answers.filter(x => x.id !== answerId);
    }
    return initialAnswerSet.answers.map(x =>
      x.members ? { ...x, members: x.members.filter(m => m.id !== answerId) } : x,
    );
  };

  const modifiedAnswerSet = {
    ...initialAnswerSet,
    answers: getNextAnswers(),
  };

  const { data: updatedAnswerSet } = await api.put(
    `/shell/digibooks/${digibookId}/answer-sets/${setId}`,
    modifiedAnswerSet,
  );

  dispatch({
    type: CodexEditorActionType.REMOVE_ANSWER_FROM_SET,
    payload: {
      set: updatedAnswerSet,
    },
  });
};

export const addToAnswerGroup = (groupId, answerId) => async (dispatch, getState) => {
  const { id: digibookId } = getInitialState(getState());
  const answerSets = getAnswerSetsForCurrentPages(getState());
  const selectedSet = getSelectedAnswerSetId(getState());
  const initialAnswerSet = answerSets.find(set => set.id === selectedSet);

  const group = initialAnswerSet.answers.find(a => a.id === groupId);
  const answer = initialAnswerSet.answers.flatMap(a => a.members || [a]).find(x => x.id === answerId);

  const members = [...group.members, answer];

  const nextAnswers = initialAnswerSet.answers
    .filter(a => a.id !== answer.id)
    .map(a => (a.members ? { ...a, members: a.members.filter(m => m.id !== answerId) } : a))
    .map(a => (a.id === groupId ? { ...a, members } : a));

  const modifiedAnswerSet = {
    ...initialAnswerSet,
    answers: nextAnswers,
  };

  const { data: updatedAnswerSet } = await api.put(
    `/shell/digibooks/${digibookId}/answer-sets/${selectedSet}`,
    modifiedAnswerSet,
  );

  dispatch({
    type: CodexEditorActionType.UPDATE_ANSWER_SET_ANSWERS,
    payload: {
      set: updatedAnswerSet,
      selectedAnswer: answerId,
    },
  });
};

export const removeFromAnswerGroup = (answerId, moveToIndex) => async (dispatch, getState) => {
  const { id: digibookId } = getInitialState(getState());
  const answerSets = getAnswerSetsForCurrentPages(getState());
  const selectedSet = getSelectedAnswerSetId(getState());

  const initialAnswerSet = answerSets.find(set => set.id === selectedSet);

  const group = initialAnswerSet.answers.find(a => a.members?.some(m => m.id === answerId));
  const answer = group?.members.find(a => a.id === answerId);

  if (!group || !answer) return;

  const copy = [...initialAnswerSet.answers];
  copy.splice(moveToIndex, 0, answer);

  const nextAnswers = copy.map(a =>
    a.id === group.id ? { ...a, members: a.members.filter(m => m.id !== answerId) } : a,
  );

  const modifiedAnswerSet = {
    ...initialAnswerSet,
    answers: nextAnswers,
  };

  const { data: updatedAnswerSet } = await api.put(
    `/shell/digibooks/${digibookId}/answer-sets/${selectedSet}`,
    modifiedAnswerSet,
  );

  dispatch({
    type: CodexEditorActionType.UPDATE_ANSWER_SET_ANSWERS,
    payload: {
      set: updatedAnswerSet,
      selectedAnswer: answerId,
    },
  });
};

export const initDefaultDigibookLink = () => (dispatch, getState) => {
  const state = getState();
  const publishingHouseId = getCurrentPublishingHouseId(state);
  const subjectId = getCurrentHierarchySubjectId(state);
  const methodId = getCurrentHierarchyMethodId(state);
  const moduleId = getCurrentHierarchyModuleId(state);

  return dispatch(setHierarchyForDigibookLink(publishingHouseId, subjectId, methodId, moduleId));
};

export const initDigibookLink = digibookId => async (dispatch, getState) => {
  try {
    await dispatch(fetchDigibookById(digibookId));
  } catch (err) {
    dispatch(initDefaultDigibookLink());
    throw err;
  }

  const digibook = getDigibookById(getState(), digibookId);
  const {
    data: { publishingHouse, subject, method, module },
  } = await api.get(`/shell/modules/${digibook.module}/path`);

  return Promise.all([
    dispatch(setHierarchyForDigibookLink(publishingHouse.id, subject.id, method.id, module.id)),
    dispatch(fetchSubjectsByPublishingHouse(publishingHouse.id)),
    dispatch(fetchMethodsBySubject(subject.id)),
    dispatch(fetchModulesByMethod(method.id)),
    dispatch(fetchDigibooksByModule(module.id)),
  ]);
};

export const initAnswerSets = answerSets => ({
  type: CodexEditorActionType.INIT_ANSWER_SETS,
  payload: {
    answerSets,
  },
});

export const editAndSaveAnswerSet = (digibookId, set) => async dispatch => {
  const { data: savedSet } = await api.put(`/shell/digibooks/${digibookId}/answer-sets/${set.id}`, set);

  return dispatch({
    type: CodexEditorActionType.EDIT_ANSWER_SET,
    payload: {
      set: {
        ...savedSet,
        answers: updateWeights(savedSet.answers),
      },
    },
  });
};

export const editAnswerSet = set => ({
  type: CodexEditorActionType.EDIT_ANSWER_SET,
  payload: {
    set,
  },
});

export function setNodeLevel(nodeId) {
  return {
    type: CodexEditorActionType.SET_NODE_LEVEL,
    payload: {
      nodeId,
    },
  };
}

export function setCurrentNodeForPageNumber(pageNumber) {
  return (dispatch, getState) => {
    const digibook = get(getState(), ['codexEditor', 'initial']);

    if (!digibook) {
      return dispatch(clearCurrentNodeForMediaContext());
    }

    const toc = getTocByModuleId(getState(), digibook.module);

    if (!toc) return dispatch(clearCurrentNodeForMediaContext());

    const tocWithMapping = getTocNodesWithPageMapping(getState(), digibook.module);
    const matchingNodes = tocWithMapping.filter(node => pageNumber <= node.to && pageNumber >= node.from);

    if (matchingNodes.find(x => x.id === tableOfContentConstants.GENERAL_NODE_ID))
      return dispatch(setCurrentNodeForMediaContext(tableOfContentConstants.GENERAL_NODE_ID));

    let currentNode;
    let currentNodeParents;

    forEachNodeRecursive(toc.nodes, (node, parents) => {
      const isFound = matchingNodes.some(x => x.id === node.id);

      if (isFound) {
        const nodeHasSameParents = !currentNode || currentNodeParents.every(x => parents.includes(x));
        if (nodeHasSameParents) {
          currentNodeParents = parents;
          currentNode = node;
        }
      }

      return !currentNode || (currentNode && node.nodes);
    });

    if (!currentNode) return dispatch(clearCurrentNodeForMediaContext());
    return dispatch(setCurrentNodeForMediaContext(currentNode.id));
  };
}

export function setCurrentPage(bookPage, amountPagesShown, totalPages) {
  return dispatch => {
    let page = bookPage;

    if (amountPagesShown === 2 && bookPage > 1 && bookPage <= totalPages) {
      page = bookPage % 2 === 0 ? bookPage : bookPage - 1;
    }

    if (bookPage < 0) page = 0;
    if (bookPage > totalPages + 1 && totalPages > 0) page = totalPages + 1;

    dispatch(setCurrentNodeForPageNumber(page));

    return dispatch({
      type: CodexEditorActionType.SET_CURRENT_PAGE,
      payload: {
        page,
      },
    });
  };
}

export const initManualMapping = manualMapping => ({
  type: CodexEditorActionType.INIT_MANUAL_MAPPING,
  payload: {
    manualMapping,
  },
});

export const addManualMapping = () => async dispatch => {
  const data = {
    tempId: shortid.generate(),
    excludeFrom: 'manual',
    from: undefined,
    to: undefined,
  };

  return dispatch({ type: CodexEditorActionType.ADD_MANUAL_MAPPING, payload: data });
};

export const saveManualMapping = (digibookId, manualMappingToSave, index) => async dispatch => {
  const { data: manualMapping } = await api.post(`/shell/digibooks/${digibookId}/manual-mapping`, manualMappingToSave);

  return dispatch({ type: CodexEditorActionType.SAVE_MANUAL_MAPPING, payload: { manualMapping, index } });
};

export const updateManualMapping = (digibookId, manualMappingToUpdate, index) => async dispatch => {
  const { data: manualMapping } = await api.put(
    `/shell/digibooks/${digibookId}/manual-mapping/${manualMappingToUpdate.id}`,
    manualMappingToUpdate,
  );

  return dispatch({ type: CodexEditorActionType.SAVE_MANUAL_MAPPING, payload: { manualMapping, index } });
};

export const deleteManualMapping = (digibookId, mapping, index) => async dispatch => {
  if (mapping.id) {
    await api.delete(`/shell/digibooks/${digibookId}/manual-mapping/${mapping.id}`);
  }

  return dispatch({
    type: CodexEditorActionType.DELETE_MANUAL_MAPPING,
    payload: {
      index,
    },
  });
};

export function initializeEditorStateFor(digibookId) {
  return (dispatch, getState) => {
    const module = getCurrentHierarchyModuleId(getState());

    if (!module) return dispatch(setInitialEditorState({}));

    if (!digibookId) {
      const initialState = {
        module,
        productionalState: 0,
      };

      return dispatch(fetchTableOfContentFor(initialState.module)).then(() => {
        initialState.pageRanges = getTocNodes(getState(), initialState.module).map(({ id }) => {
          const node = {
            nodeId: id,
          };

          if (id === 'general') {
            node.from = 0;
            node.to = 0;
            node.keepMediaVisibleTo = 0;
          }

          return node;
        });

        return dispatch(setInitialEditorState(initialState));
      });
    }

    return dispatch(forceFetchDigibookById(digibookId))
      .then(data => Promise.all([data, dispatch(fetchTableOfContentFor(data.module))]))
      .then(([data]) => {
        const {
          linkAreas: omitLinkAreas,
          answerSets: omitAnswerSets,
          manualMapping: omitManualMapping,
          ...restOfDigibook
        } = data;
        const initialState = {
          ...restOfDigibook,
        };

        if (data.bookLayer) dispatch(setFileForLayer('bookLayer', data.bookLayer));
        if (data.answerLayer) dispatch(setFileForLayer('answerLayer', data.answerLayer));
        if (data.cover) dispatch(setFileForLayer('cover', data.cover));
        if (data.backCover) dispatch(setFileForLayer('backCover', data.backCover));
        if (data.manual) dispatch(setFileForLayer('manual', data.manual));

        const allNodes = getTocNodes(getState(), data.module).map(({ id }) => ({ nodeId: id }));

        const { pageRanges } = data;

        if (pageRanges && pageRanges.length > 0) {
          initialState.pageRanges = allNodes.map(
            node => pageRanges.find(range => range.nodeId === node.nodeId) || node,
          );
        } else {
          initialState.pageRanges = allNodes;
        }

        dispatch(initLinkAreas(data.linkAreas));
        dispatch(initAnswerSets(data.answerSets));
        dispatch(initManualMapping(data.manualMapping));
        dispatch(setActiveTab(0));

        return dispatch(setInitialEditorState(initialState));
      });
  };
}
